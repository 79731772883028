import React from 'react'
import { Typograph } from '../shared/Headingtext'
import { Basicbutton } from '../shared/button'
import HeroImg from "../../assets/img/HeroImg.png"
import Container from '../shared/Container'
import HEROBG from '../../assets/img/heroBG.jpg'
import { scrollToSection } from '../../utils/methods'


const Hero = () => {
  return (
    <div id='home' style={{backgroundImage: `url(${HEROBG})`}} className='bg-cover bg-center pt-[5.5rem]'>
        <Container>
            <div className='flex flex-col gap-10 md:gap-0 md:flex-row justify-center items-center pt-[3rem] lg:pt-10'>
                <div  data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000" className='flex-1 space-y-4'>
                    <h1 className='text-primay text-center md:text-left font-[800] text-[28px] sm:text-[32px] md:text-[40px] lg:text-[52px] font-Robo leading-[50px] lg:leading-[60px]'>From Trash to <span className='text-[#E0AA1F] bg-[#F9EED2] text-[28px] sm:text-[32px] md:text-[44px] lg:text-[56px] px-2 rounded-md'>Treasure:</span> </h1>

                    <div>
                        <p className='text-primary text-center md:text-left font-Robo font-[400] text-[24px] lg:text-[40px]'>Shaping a Sustainable Future</p>
                    </div>
                    <div className='w-full flex justify-center md:justify-normal items-center' onClick={() => scrollToSection(`contact`)}>
                        <Basicbutton url='/' text='Get Started'/>
                    </div>

                </div>
                <div  data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000" className='flex-1 p-8 md:p-0 flex justify-center items-center'>
                    <img className='lg:h-[500px]' src={HeroImg} alt="hero" />
                </div>
            </div>        
        </Container>        
    </div>


  ) 
}

export default Hero

// Ade Gen 08074928630