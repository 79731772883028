export const navLinks = [
    {
        name: 'Home',
        url: '/',
        id:'home'
    },
    {
        name: 'About Us',
        url: '/about',
        id:'about'
    },
    {
        name: 'What We Collect',
        url: '/collectibles',
        id:'collectibles'
    },
    {
        name: 'Partners',
        url: '/partners',
        id:'partners'
    },
    {
        name: 'Contact Us',
        url: '/contact',
        id:'footer'
    },
]
export const footerLinks = [
    {
        name: 'Cookie Policy',
        url: '/'
    },
    {
        name: 'End User License Agreement',
        url: '/about'
    },
    {
        name: 'Privacy Policy',
        url: '/collectibles'
    },
    {
        name: 'Terms and Condition',
        url: '/partners'
    },
]