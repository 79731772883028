import React from 'react'
import Headingtext, { Typograph } from '../shared/Headingtext'
import Container from '../shared/Container'
import AboutImg from '../../assets/img/Deco-video.png'

const About = () => {
  return (
    <div id='about'>
      <Container>
          <div  data-aos="fade-up"
        data-aos-anchor-placement="center-bottom" className='flex items-center flex-col-reverse gap-8 md:gap-4 lg:gap-0 md:flex-row pt-[5.5rem] md:py-16 '>
              <div className='flex-1 md:w-[50%] px-10 md:px-0'>
                  <img src={AboutImg} className='lg:h-[400px] ' alt="aboutimg" />
              </div>
              <div className='flex-1 md:w-[50%] '>
                <div className='lg:px-[8rem]'>
                  {/* desktop */}
                  <div className='hidden md:block'>
                    <Headingtext align='left' text='Our Story' />
                    <Typograph align='left' text="Golden Garbage is more than a waste management company; we're catalysts for change. Founded with a mission to revolutionize recycling practices, we're committed to creating a cleaner, greener future for generations to come."/>
                  </div>
                  {/* mobile */}
                  <div className='block md:hidden'>
                    <Headingtext align='center' text='Our Story' />
                    <Typograph align='center' text="Golden Garbage is more than a waste management company; we're catalysts for change. Founded with a mission to revolutionize recycling practices, we're committed to creating a cleaner, greener future for generations to come."/>
                  </div>

                </div>
                
              </div>

          </div>        
      </Container>      
    </div>


  )
}

export default About