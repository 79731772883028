import React from 'react'
import Container from '../shared/Container'
import Headingtext, { Typograph } from '../shared/Headingtext'
import { Basicbutton } from '../shared/button'
import Bin from '../../assets/img/Leftbins.png'
import Banner from '../../assets/img/yellowBanner.png'

const Contact = () => {
  return (
    <div id='contact' className='pt-[5.5rem]'>
      {/* web view */}
      <Container>
      <div  data-aos="flip-up" style={{backgroundImage: `url(${Banner})`}} className='hidden rounded-2xl lg:flex justify-between items-center relative bg-cover bg-center bg-no-repeat  lg:py-14 lg:my-10 flex-col-reverse lg:flex-row'>
        <img src={Bin} className='absolute hidden lg:block bottom-0 left-8 h-[250px]' alt="" />
        <div className='flex-1 hidden lg:block'>
        </div>

        <div className='flex-1 flex flex-col gap-4 justify-center md:justify-normal lg:items-start items-center '>
          <h1 className='text-[24px] md:text-[36px] font-[700] font-Robo text-whyte text-center lg:text-left'>Start Scheduling Today!</h1>

          {/* web */}
          <p className='text-whyte font-[300] text-[14px] w-[70%] font-Robo text-center lg:text-left hidden lg:block'>Join the movement towards a cleaner environment <br />and earn rewards for your recyclable materials.</p>

          {/* mobile */}
          <p className='text-whyte font-[300] text-[14px] w-[70%] font-Robo text-center lg:text-left block lg:hidden'>Join the movement towards a cleaner environment and earn rewards for your recyclable materials.</p>
          <Basicbutton url='/' text='Get Started'/>
        </div>

        
      </div>

    </Container>

    {/* mobile view */}
      <div className='lg:hidden block'>

        <div data-aos="flip-up" style={{backgroundImage: `url(${Banner})`}} className='flex justify-between items-center relative bg-cover bg-center bg-no-repeat py-14 my-10 flex-col-reverse lg:flex-row'>
            <div className='flex-1 hidden lg:block'>
            </div>

            <div className='flex-1 flex flex-col gap-4 justify-center md:justify-normal lg:items-start items-center '>
              <h1 className='text-[24px] md:text-[36px] font-[700] font-Robo text-whyte text-center lg:text-left'>Start Scheduling Today</h1>

              {/* web */}
              <p className='text-whyte font-[300] text-[14px] w-[70%] font-Robo text-center lg:text-left hidden lg:block'>Join the movement towards a cleaner environment <br />and earn rewards for your recyclable materials.</p>

              {/* mobile */}
              <p className='text-whyte font-[300] text-[14px] w-[70%] font-Robo text-center lg:text-left block lg:hidden'>Join the movement towards a cleaner environment and earn rewards for your recyclable materials.</p>
              <Basicbutton url='/' text='Get Started'/>
        </div>
        
        </div>
        <div className='w-full flex justify-center items-center p-4 '>
          <img src={Bin} className=' lg:hidden bottom-0 left-8 h-[250px]' alt="" />
        </div>
        
        
      </div>
    </div>

  )
}

export default Contact