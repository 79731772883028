import React from 'react'
import Headingtext, { Typograph } from '../shared/Headingtext'
import MissionImg from "../../assets/img/TakeWaste.png"
import Container from '../shared/Container'

const Mission = () => {
  return (
    <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" id='mission'  className='lg:bg-[#F9EED2] bg-opacity-30 xl:h-[90vh] overflow-hidden '>
        <Container>
            <div className='py-10 space-y-10'>
                <div className='space-y-4'>
                <h1 style={{textAlign:`center`}} className='font-Robo font-[800] text-[24px] md:text-[40px] leading-[40px] md:leading-[61px] text-primary'>Your Path to Sustainable Living <span className='text-[#E0AA1F]'>Starts Here</span> </h1>
                    <div className='w-full flex justify-center items-center'>
                    <div className=' md:w-[80%] lg:w-[60%]'>
                        <Typograph align='center' text='Take charge of your environmental footprint by earning rewards for your recyclable materials. With Golden garbage, turning waste into cash has never been easier.'/>                        
                    </div>
                    </div>


                </div>
                <div className='flex flex-col lg:flex-row justify-center items-center'>
                    <div data-aos="zoom-in"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1500" className='flex-1'>
                        <img className='h-[230px] md:h-[300px]' src={MissionImg} alt="mission" />
                    </div>
                    <div
                    data-aos="fade-left"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                    className='flex-1 flex -mt-10 lg:mt-0 justify-center items-center relative lg:-ml-10'>
                        <div className='w-[90%] lg:w-full bg-white rounded-md shadow-lg p-4 space-y-3'>
                            <p className='revolution text-primary text-center md:text-left font-bold text-[16px] '>JOIN THE RECYCLING REVOLUTION</p>
                            <p className='revolution-desc text-center text-[14px] md:text-left'>Every action you take on our platform not only benefits you financially but also makes a positive impact on the environment.</p>
                        </div>                        
                    </div>

                </div>
            </div>        
        </Container>        
    </div>


  )
}

export default Mission