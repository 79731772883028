import React from 'react'
import Container from '../../shared/Container'
import { Link } from 'react-router-dom'
import { footerdata } from './data'
import Banner from '../../../assets/img/BrownBanner.jpg'
import Logo from '../../../assets/img/Logo-dac.png'

const Footer = () => {


  return (
    <div id='footer' style={{backgroundImage: `url(${Banner})`}} className='bg-cover bg-center py-20'>
        <Container>
            <div>
                <div className='w-full flex flex-col lg:flex-row gap-8 lg:gap-0 lg:items-center'>
                    <div className='flex-1 flex items-center flex-col lg:flex-row'>
                        <div>
                            <img className='h-[50px]' src={Logo} alt="pakam" />
                        </div>
                        <div>

                        </div>
                    </div>

                    <div className='flex-1 flex flex-col lg:flex-row justify-between gap-8 lg:gap-0'>
                        {
                            footerdata.map((item,i)=>{
                                return(
                                <div key={i} className='space-y-4'>
                                    <p className='font-Robo text-whyte font-[700] text-[20px] text-center lg:text-left'>{item.title}</p>
                                    {
                                        item.children.map((child,id)=>{
                                            return(
                                                <>
                                                    {
                                                        "url" in child ?
                                                        <ul className='space-y-3 w-full'>
                                                            <li className='w-full flex justify-center items-center lg:justify-normal lg:items-start ' ><Link className='text-center w-full lg:text-left font-Robo text-whyte font-[400] text-[14px] ' to={child.url}>{child.name}</Link></li>
                                                        </ul>
                                                        :
                                                        <ul className='space-y-3'>
                                                            <li className='justify-center items-center lg:justify-normal lg:items-start w-full font-Robo text-whyte font-[400] text-[14px] flex gap-3'> <img className='hidden lg:block h-[20px] w-[20px]' src={child.icon} alt="icon"  /><span>{child.name}</span></li>
                                                        </ul>
                                                    }                                        
                                                </>
                                            )
                                        })
                                    }
                                    
                                </div>                            
                                )
                            })
                        }

                        <div>

                        </div>
                    </div>                    
                </div>

                <div className='pt-8'>
                    <p className='font-Robo text-center lg:text-left text-whyte font-[400] text-[14px]'>@ 2024. All rights reserved.</p>
                </div>                
            </div>

        
        </Container>

    </div>
  )
}

export default Footer