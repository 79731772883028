import React from 'react'
import Hero from '../components/hero/Hero'
import Navbar from '../components/common/header/Navbar'
import Footer from '../components/common/footer/Footer'
import Contact from '../components/contact.tsx/Contact'
import Mission from '../components/mission/Mission'
import About from '../components/about/About'
import FootPrint from '../components/footprint/FootPrint'
import Partners from '../components/partners/Partners'
import Collectible from '../components/collectibles/Collectible'
import Operations from '../components/operations/Operations'
import Empowering from '../components/empowering/Empowering'
import Gallery from '../components/gallery/Gallery'
import MainLayout from '../utils/layouts/MainLayout'

const Home = () => {
  return (
    <>
      <Hero/>
      <About/>
      <Mission/>
      <Empowering/>
      <Operations/>
      <FootPrint/>
      <Collectible/>
      <Partners/>    
      <Gallery/>
      <Contact/>  

    </>
  )
}

export default Home