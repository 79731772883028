import React from 'react'
import G1 from '../../assets/img/PictureG1.png'
import G2 from '../../assets/img/PictureG2.png'
import G3 from '../../assets/img/PictureG3.png'
import G4 from '../../assets/img/PictureG4.png'
import G5 from '../../assets/img/PictureG5.png'

const Gallery = () => {
  return (
        <div  data-aos="fade-right"
        data-aos-easing="ease-out-cubic"
                data-aos-duration="2000" className='flex flex-col gap-1 -mb-14 pt-6 lg:-mb-20 lg:pt-10 lg:py-14'>
            <div className='lg:h-[250px] flex gap-1 lg:gap-2 flex-col lg:flex-row'>
                <div className='h-full w-full lg:w-3/5'>
                    <img className='h-full w-full object-cover' src={G1} alt="gallery" />
                </div>
                <div className='w-full  lg:w-2/5 h-full'>
                    <div className=' flex justify-between items-center relative bg-[#BB8E1A] w-full h-full p-4 py-8 lg:py-0 lg:p-10'>
                        {/* Desktop view */}
                        <div className='hidden lg:flex flex-1 flex-col gap-4'>
                            <h1 className='text-[36px] font-[700] leading-[40px] font-Robo text-whyte'>We're catalysts for <br />recycling change.</h1>
                            <p className='text-whyte font-[300] text-[14px] font-Robo '>Golden garbage is here to revolutionize recycling <br />practices, we're committed to creating a cleaner, greener <br />future for generations to come</p>
                        </div>

                        {/* Mobile view */}
                        <div className='flex-1 flex justify-center items-center lg:hidden flex-col gap-4'>
                            <h1 className='text-[24px] md:text-[32px] font-[700] leading-[30px] md:leading-[40px] font-Robo text-whyte text-center'>We're catalysts for <br />recycling change.</h1>
                            <p className='text-whyte font-[300] text-[16px] font-Robo text-center md:w-[80%]'>Golden garbage is here to revolutionize recycling practices, we're committed to creating a cleaner, greener future for generations to come</p>
                        </div>

                        
                    </div>
                </div>
            </div>
            <div  data-aos="fade-left" 
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"className='flex justify-center items-center gap-1 lg:gap-2'>
                <div> <img src={G2} alt="gallery" /></div>
                <div className=''> <img src={G3} alt="gallery" /></div>
                <div> <img src={G4} alt="gallery" /></div>
                {/* <div className='block md:hidden'> <img src={G3} alt="gallery" /></div> */}
                <div className=''> <img src={G5} alt="gallery" /></div>
            </div>
        </div>

  )
}

export default Gallery