import React from 'react'
import Headingtext from '../shared/Headingtext'
import EatLogo from '../../assets/img/Eat‘NGo 1.png'
import BlackLogo from '../../assets/img/BLACK-LOGO 1.png'
import FoodLogo from '../../assets/img/image 1.png'
import LawLogo from '../../assets/img/neer-logo 1.png'
import PakamLogo from '../../assets/img/Pakam (1) 1.png'


const Partners = () => {
  return (
    <div id='partners'  className='flex flex-col justify-center items-center gap-6 pt-[5.5rem] mb-10 sm:mb-0'>
        <Headingtext align='center' text='Our Top Partners'/>

        <div id='partner-box'  className=' lg:bg-[#F9EED2] md:py-8 flex justify-center items-center'>
          <div className='flex justify-center items-center gap-6 lg:gap-14 w-[90%] lg:w-[60%] lg:py-8'>
            {
                partners.map((item, id)=><div key={id} className=''><img src={item}/></div>)
            }            
            {/* <div className='h-[110px] w-[135px]'><img className='h-full w-full' src={PakamLogo}/></div> */}
            
          </div>

        </div>
    </div>
  )
}

export default Partners

const partners = [
    EatLogo,BlackLogo, FoodLogo,LawLogo,PakamLogo
]