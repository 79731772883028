import React from 'react'
interface containerProps{
    children: React.ReactNode
}
const Container = ({children}:containerProps) => {
  return (
    <div className='px-[1rem] md:px-[3.5rem]'>
        {children}
    </div>
  )
}

export default Container