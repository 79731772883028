import React from 'react'
import Headingtext from '../shared/Headingtext'
import Processing from '../../assets/icons/processing.svg'
import Sales from '../../assets/icons/sales.svg'
import Collection from '../../assets/icons/collect.svg'
import Sorting from '../../assets/icons/Iconsorting.svg'
import Container from '../shared/Container'

const Operations = () => {
  return (
    <Container>
        <div data-aos="fade-up" className='py-10 lg:py-20'>
            <Headingtext align='center' text='Our Operations'/>
            <div className='flex justify-between items-center flex-wrap mt-10 gap-10 lg:gap-0'>
                {OperationsData?.map((item, id)=>{
                    return(
                        <div data-aos="zoom-out-right" className='px-6 md:px-0 md:w-[40%] lg:w-[20%] flex flex-col gap-3 justify-center items-center'>
                            <img src={item.imgLink} alt="operations" className='w-[60px] h-[60px]' />

                            <p className='text-center text-primary font-[700] text-[16px] font-Robo'>{item.title}</p>

                            <p className='text-center text-primary font-[400] text-[14px] font-Robo'>{item.desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>        
    </Container>

  )
}

export default Operations

const OperationsData = [
    {
        title: 'Collection',
        imgLink:Collection,
        desc:'Utilizing innovative EV cargo tricycles, we efficiently collect and transport waste from its source to our processing centers'
    },
    {
        title: 'Sorting',
        imgLink:Sorting,
        desc:'Our dedicated team conducts meticulous manual sorting at our collection centers, ensuring materials are properly separated for processing.'
    },
    {
        title: 'Pre-Processing',
        imgLink:Processing,
        desc:'We employ solar-powered bailers to prepare sorted materials for recycling, minimizing environmental impact while maximizing efficiency.'
    },
    {
        title: 'Sales',
        imgLink:Sales,
        desc:'Leveraging targeted campaigns and logistics expertise, we connect recycled materials with industries in need, ensuring a seamless supply chain.'
    },
]