import React from 'react'

interface typoProps{
    text:string;
    align: 'start' | 'end' | 'left' | 'right' | 'center' 
}

export const Typograph = ({text, align}:typoProps) =>{
    return (
        <p style={{textAlign:`${align}`}} className='typograph text-primary font-[400] font-Robo text-[16px] leading-7'>{text}</p>
    )
}

const Headingtext = ({text, align}:typoProps) => {
  return (
    <h1 style={{textAlign:`${align}`}} className='font-Robo font-[800] text-[24px] md:text-[40px] leading-[61px] text-primary'>{text}</h1>
  )
}

export default Headingtext