import { useEffect } from 'react';
import './App.css';
import Home from './pages/Home';
import initializeAOS from './aosConfig'
import { Route, Router, Routes } from 'react-router-dom';
import About from './components/about/About';
import Contact from './components/contact.tsx/Contact';
import MainLayout from './utils/layouts/MainLayout';


function App() {
  useEffect(() => {
    initializeAOS(); // Initialize AOS on the client-side
  }, []);

  return (
    <>
    <MainLayout>
        <Home/>   
    </MainLayout>
    </>
  );
}

export default App;
