import { Link } from "react-router-dom";

interface buttonProps{
    url: string;
    text:string
}

export const Basicbutton = ({url, text}: buttonProps) =>{
    return(
        <Link to={url}>
            <button className="bg-brown rounded-md py-2 px-8 border-2 border-brown text-whyte font-Robo font-[600] text-[18px] leading-6 tracking-[0.5px] transition-all duration-500 hover:bg-[#A77A00] hover:border-[#A77A00]">{text}</button>
        </Link>
        
    )
}