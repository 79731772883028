import React, { FC, ReactNode } from 'react'
import Navbar from '../../components/common/header/Navbar'
import Footer from '../../components/common/footer/Footer'

interface LayoutProps{
    children: ReactNode
}

const MainLayout: FC<LayoutProps> = ({children}) => {
  return (
    <div className=''>
        <Navbar/>
        <div>{children}</div>
        <Footer/>
    </div>
  )
}

export default MainLayout