import Locaiton from '../../../assets/icons/carbon_location-filled.svg'
import Phone from '../../../assets/icons/ri_phone-fill.svg'
import Email from '../../../assets/icons/material-symbols-light_mail.svg'
import Insta from '../../../assets/icons/Vector.svg'

export const footerdata =[
    {
        title: 'Legal',
        children: [
            {
                name: 'Cookie Policy',
                url: '/'
            },
            {
                name: 'End User License Agreement',
                url: '/'
            },
            {
                name: 'Privacy Policy',
                url: '/'
            },
            {
                name: 'Terms and Conditions',
                url: '/'
            },
        ]
    },
    {
        title: 'Contact Us',
        children: [
            {
                icon: Locaiton,
                name: '19, Adeoye Adeyemi wasimi street, Maryland',
            },
            {
                icon: Phone,
                name: '+234 - 912 261 6778',
            },

            {
                icon: Email,
                name: 'goldengarbagelimited@gmail.com',
            },
            {
                icon: Insta,
                name: '@goldengarbage_ ng',
            },
        ]
    },
]
