import React, { useEffect, useState } from 'react'
import Headingtext from '../shared/Headingtext'
import { Basicbutton } from '../shared/button'
import Container from '../shared/Container'
import CountUp from 'react-countup'
import Rope from "../../assets/img/Vector 32ftRope.png"
import Banner from "../../assets/img/yellowBanner.png"
import Foot from '../../assets/img/mdi_footprints.png'

const FootPrint = () => {

  const [countOn, setCountOn] = useState(false)

  useEffect(() => {
      const handleScroll = () => {
          const scrollPosition = window.scrollY;
          if (scrollPosition > 1500) {
              setCountOn(true);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <div>
      {/* desktop view */}
        <Container>
        <div style={{backgroundImage: `url(${Banner})`}} className='hidden lg:block rounded-2xl  relative bg-cover bg-center py-14 lg:my-6 -z-10 pl-8'>


          {/* <img src={Banner} alt="banner" className='absolute top-0 left-0 -z-10 w-full' /> */}
          <img src={Rope} alt="rope" className='absolute hidden lg:block -z-10 right-0 top-0 w-[77%] h-[90%]' />
          <img src={Foot} alt="" className='absolute right-0 top-0 h-[100px] md:h-[250px]' />
          <img src={Foot} alt="" className='absolute left-[20px] md:left-[50px] bottom-0  h-[100px]' />

          <div className='flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between items-center px-6 py-10 lg:w-[65%]'>
            <div>
              <p className='text-white font-[700] font-Robo text-[36px] lg:text-[40px]'>Our Footprint</p>
            </div>
            <div className='flex flex-col justify-center items-center space-y-4'>
              <span className='text-whyte font-[600] lg:font-[800] font-Robo text-[40px] md:text-[95px] leading-[70px]'>{countOn && <CountUp start={0} end={45} duration={5} delay={0}/>}</span>
              <small className='text-whyte text-[16px] md:text-sm tracking-wider font-[400] font-Robo'>TONNES COLLECTED</small>
            </div>
            <div className='flex flex-col justify-center items-center space-y-4'>
              <span className='text-whyte font-[600] lg:font-[800] font-Robo text-[40px] md:text-[95px] leading-[70px]'>{countOn && <CountUp start={0} end={32} duration={5} delay={0}/>}</span>
              <small className='text-whyte text-[16px] md:text-sm tracking-wider font-[400] font-Robo'>TONNES SOLD</small>
            </div>          
          </div>
          
        </div>

      </Container>    

      {/* mobile view */}

      <div style={{backgroundImage: `url(${Banner})`}} className='lg:hidden  relative bg-cover bg-center  py-4 my-10 -z-10'>


          {/* <img src={Banner} alt="banner" className='absolute top-0 left-0 -z-10 w-full' /> */}
          <img src={Rope} alt="rope" className='absolute hidden lg:block -z-10 right-0 top-0 w-[80%] h-[90%]' />
          <img src={Foot} alt="" className='absolute right-0 top-0 h-[100px] md:h-[250px]' />
          <img src={Foot} alt="" className='absolute left-[20px] md:left-[50px] bottom-0  h-[100px]' />

          <div className='flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between items-center px-6 py-10 lg:w-[60%]'>
            <div>
              <p className='text-white font-[700] font-Robo text-[32px]'>Our Footprint</p>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <span className='text-whyte font-[800] font-Robo text-[80px] leading-[70px]'>{countOn && <CountUp start={0} end={45} duration={5} delay={0}/>}</span>
              <small className='text-whyte text-lg tracking-wider font-[400] font-Robo'>TONNES COLLETED</small>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <span className='text-whyte font-[800] font-Robo text-[80px] leading-[70px]'>{countOn && <CountUp start={0} end={32} duration={5} delay={0}/>}</span>
              <small className='text-whyte text-lg tracking-wider font-[400] font-Robo'>TONNES SOLD</small>
            </div>          
          </div>

      </div>
    </div>

  )
}

export default FootPrint