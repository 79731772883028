import React from 'react'
import Emp1 from '../../assets/img/PictureEmp1.png'
import Emp2 from '../../assets/img/PictureEmp2.png'

const Empowering = () => {
  return (
    <div data-aos="fade-up" className='py-14 space-y-10 w-full flex flex-col justify-center items-center'>

        <h1 style={{textAlign:`center`}} className='w-[90%] sm:w-full font-Robo font-[800] text-[28px] md:text-[38px] leading-[40px] md:leading-[61px] text-primary'><span className='text-[#A77A00]'>Empowering Aggregators,</span> Enriching Communities</h1>

        <div className='w-full flex flex-col lg:flex-row justify-center items-center gap-10 px-6 md:px-0'>
            {
                empData.map((el, id)=>{
                    return(
                        <div data-aos="zoom-out" key={id} className='border-[2px] lg:border-[4px] border-[#A77A00] flex flex-col rounded-2xl lg:rounded-xl shadow-md h-[250px] md:h-[400px] w-[95%] md:w-[70%] lg:w-[440px]'>
                            <div className='h-[70%] w-full'><img className='h-full w-full rounded-t-xl lg:rounded-t-lg' src={el.imgLink} alt="emp" /></div>
                            <div className='flex justify-center items-center h-[30%] px-3'><p className='text-center text-primary font-Robo font-[400] emp-desc text-[14px]'>{el.text}</p></div>
                        </div>                        
                    )
                })
            }

        </div>
    </div>
  )
}

export default Empowering

const empData = [
    {
        imgLink: Emp1,
        text:'We provide local aggregators with the tool and support they need to thrive, ensuring fair compensation for their efforts.'
    },
    {
        imgLink: Emp2,
        text:'By transforming waste into valuable resources, we create economic opportunities and contribute to sustainable development'
    },

]