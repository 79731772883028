import React from 'react'
import Headingtext from '../shared/Headingtext'
import Marquee from 'react-fast-marquee'

import Plastic from '../../assets/img/plastic.png'
import Metal from '../../assets/img/metal.png'
import Pet from '../../assets/img/pet.png'
import Carton from '../../assets/img/carton.png'
import Paper from '../../assets/img/paper.png'
import Bag from '../../assets/img/bag.png'

const Collectible = () => {
  return (
    <div id='collectibles' className=' w-full pt-[5.5rem] -mb-10 lg:py-20'>
    <Headingtext align='center' text='What We Collect'/>

    <div className="mt-8">
    <Marquee
      gradient={true}
    //   gradientColor={[248, 251, 251]}
      direction= 'left'
      
      // speed={speed}
    >
      <div
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="-8"
        className='flex justify-between items-center'
      >

        {imgUrl.map((el:any, i:any) => (        
        <div className='flex justify-center items-center flex-col mx-6 gap-6 lg:gap-3'>
            <div style={{backgroundColor: 'rgba(217, 217, 217, 1)'}} className='h-[100px] w-[110px] md:h-[160px] md:w-[180px] rounded-lg'>
            <img
                key={i}
                src={el.imgLink}
                className={`w-full h-full rounded-lg`}
            />
            </div> 

            <p className='text-center font-Raleway text-[#464F54] font-[500] text-[20px] tracking-[0.75px]'>{el.title}</p>           
        </div>


        ))}
      </div>
    </Marquee>
  </div>
  
</div>
  )
}

export default Collectible

const imgUrl=[
    {
        imgLink: Plastic,
        title: 'Plastic'
    },    
    {
        imgLink: Pet,
        title: 'Pet bottles'
    },
    {
        imgLink: Carton,
        title: 'Cartons'
    },

    {
        imgLink: Paper,
        title: 'Paper'
    },
    {
        imgLink: Bag,
        title: 'Bag'
    },
    {
        imgLink: Metal,
        title: 'Metal'
    },
]